<template>
  <div>
    <search-carrier-product @submit="submitSearch" />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button
          type="primary"
          @click="showImportModal"
          v-if="isShowMenu('carrier_products/import')"
        >导入通用套餐
        </a-button>

        <update-carrier-product-effective
          v-if="isShowMenu('carrier_products/effective')"
          :selected-row-keys="selectedRowKeys"
          @completed="fetchData"
        />

        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 'max-content' }"
      @change="sortChange"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      row-key="id"
    >
      <span slot="cards_count_title">
        <a-tooltip>
          <template slot="title">
            已划拨+库存
          </template>
          关联卡数(不含销卡)
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>

      <span slot="traffic_property" slot-scope="text" :class="{ 'pending-set': text === '待设置'}">
        {{ text }}
      </span>

      <span slot="price" slot-scope="text" :class="{ 'pending-set': text === '待设置'}">
        {{ text }}
      </span>

      <span slot="excess_price" slot-scope="text" :class="{ 'pending-set': text === '待设置'}">
        {{ text }}
      </span>

      <span slot="actions" slot-scope="text, record">
        <a-dropdown>
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            操作 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-if="isHasEditPermission">
              <a @click="showEditModal(record.id)">编辑</a>
            </a-menu-item>
            <a-menu-item>
              <a @click="showOperationHistoryModal(record.id)">操作历史</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增模态框 -->
    <import-carrier-product
      v-if="isShowImportModal"
      :carrier-account-id="carrierAccountId"
      :visible.sync="isShowImportModal"
      @completed="fetchData"
    />

    <!-- 编辑模态框 -->
    <edit-carrier-product
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :id="editingId"
      :carrier-account-id="carrierAccountId"
      @completed="fetchData"
    />

    <operation-history-list-modal
      v-if="isShowOperationHistoryModal"
      :reference-id="showingOperationHistoryId"
      reference-type="CarrierProduct"
      :visible.sync="isShowOperationHistoryModal"
    />
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { findCarrierProducts, exportCarrierProducts } from '@/api/carrier_product'
import SearchCarrierProduct from '@/views/carrier_products/Search'
import Pagination from '@/components/Pagination'
import { exportExcel } from '@/api/excel'

export default {
  name: 'CarrierProductList',
  components: {
    SearchCarrierProduct,
    Pagination,
    OperationHistoryListModal: () => import('@/views/operation_histories/Modal'),
    ImportCarrierProduct: () => import('@/views/carrier_products/import/index'),
    EditCarrierProduct: () => import('@/views/carrier_products/Edit'),
    UpdateCarrierProductEffective: () => import('@/views/carrier_products/UpdateEffective')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowImportModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      editingId: 0, // 正在编辑的记录id
      isShowOperationHistoryModal: false, // 是否显示操作历史弹窗
      showingOperationHistoryId: 0, // 正在显示操作历史的记录id
      selectedRowKeys: [],
      pagination: {
        total_count: 0
      },
      query: { effective: 1 },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '套餐名称',
          fixed: 'left',
          dataIndex: 'name'
        },
        {
          title: '运营商套餐ID',
          dataIndex: 'carrier_package_id'
        },
        {
          title: '通用套餐名称',
          dataIndex: 'base_carrier_product_name'
        },
        {
          title: '标签',
          dataIndex: 'tags'
        },
        {
          title: '共享属性',
          dataIndex: 'traffic_share_property'
        },
        {
          title: '服务周期',
          dataIndex: 'service_cycle'
        },
        {
          title: '套餐容量',
          dataIndex: 'package_capacity'
        },
        {
          title: '语音',
          dataIndex: 'voice_capacity'
        },
        {
          title: '流量属性',
          dataIndex: 'traffic_property',
          scopedSlots: { customRender: 'traffic_property' }
        },
        {
          title: '套餐价格',
          dataIndex: 'price',
          scopedSlots: { customRender: 'price' }
        },
        {
          title: '超额资费',
          dataIndex: 'excess_price',
          scopedSlots: { customRender: 'excess_price' }
        },
        {
          title: '有效性',
          dataIndex: 'effective'
        },
        {
          slots: { title: 'cards_count_title' },
          dataIndex: 'cards_count',
          sorter: true,
          sortOrder: this.sort.sort_field === 'cards_count' ? this.sort.sort_order : false
        },
        {
          title: '操作',
          dataIndex: 'actions',
          fixed: 'right',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    },

    carrierAccountId() {
      return parseInt(this.$route.params.id)
    },

    isHasEditPermission() {
      return hasPermission('carrier_products/edit')
    }
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    },

    // 显示操作历史
    showOperationHistoryModal(id) {
      this.showingOperationHistoryId = id
      this.isShowOperationHistoryModal = true
    },

    showImportModal() {
      this.isShowImportModal = true
    },

    showEditModal(id) {
      this.editingId = id
      this.isShowEditModal = true
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findCarrierProducts(Object.assign({ carrier_account_id: this.carrierAccountId }, this.query, this.sort))
        .then((res) => {
          this.data = res.data
          this.pagination = res.pagination
          this.loading = false
        })
    },

    exportData() {
      exportCarrierProducts(Object.assign({ carrier_account_id: this.carrierAccountId }, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pending-set {
  color: red;
}
</style>
